import React from 'react';
import '../css/Auth.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import EditProfile from './EditProfile';
import CompanyProfile from './CompanyProfile';
import Search from './Search';
import CVWriter from './CVWriter';

function Auth() {
    return (
        <>
            <Router>
                <div className="container">
                    <Sidebar />
                    <div className="content">
                        <Routes>
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/search" element={<Search/>} />
                            <Route exact path="/editprofile" element={<EditProfile />} />
                            <Route exact path="/companyprofile" element={<CompanyProfile />} />
                            <Route exact path="/cvwriter" element={<CVWriter />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </>
    )
}

export default Auth
