import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Onboarding() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [age, setAge] = useState(30); // Default age set to 30
    const [location, setLocation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [cv, setCv] = useState(null);
    const [cvVideo, setCvVideo] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cvLink, setCvLink] = useState('');
    const [cvVideoLink, setcvVideoLink] = useState('');
    const [profileImageLink, setprofileImageLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingFileType, setUploadingFileType] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (userId) {
            fetchUserData(userId);
        }
    }, []);

    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(`${backendURL}/api/user/fetch/${userId}`);
            if (response.ok) {
                const userData = await response.json();
                setFirstName(userData.firstName || '');
                setLastName(userData.lastName || '');
                setFullName(userData.fullName || '');
                setAge(userData.age || 30); // Default to 30 if age not available
                setLocation(userData.location || '');
                setEmail(userData.email || '');
                setPhoneNumber(userData.phoneNumber || '');
                setCvLink(userData.cv || '');
                setcvVideoLink(userData.cvVideo || '');
                setprofileImageLink(userData.profileImage || '');
            } else {
                toast.error('Failed to fetch user data.');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('An error occurred while fetching user data.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            toast.error('You should register yourself first.');
            return;
        }

        const schema = Joi.object({
            age: Joi.number().integer().required(),
            location: Joi.string().trim().required(),
            phoneNumber: Joi.string().trim().regex(/^\+?[0-9]+$/).min(10).max(11).required().messages({
                'string.pattern.base': 'Phone number should only contain digits and may start with +',
                'string.min': 'Phone number should be at least 10 digits',
                'string.max': 'Phone number should be at most 11 digits',
            }),            
        });
        const { error } = schema.validate({ age, location, phoneNumber }, { abortEarly: false });

        if (error) {
            const validationErrors = {};
            error.details.forEach((item) => {
                validationErrors[item.path[0]] = item.message;
            });
            setErrors(validationErrors);
        } else {
            try {
                setIsLoading(true);

                const formData = new FormData();
                formData.append('age', age);
                formData.append('location', location);
                formData.append('phoneNumber', phoneNumber);

                // Add CV file if selected and validate its type
                if (cv) {
                    if (cv.type === 'application/pdf') {
                        if (cv.size > 10485760) { // 10MB size limit
                            toast.error('PDF file size exceeds the limit of 10MB.');
                            setIsLoading(false);
                            return;
                        }
                        formData.append('cv', cv);
                    } else {
                        toast.error('Only PDF files are allowed for CV!');
                        setIsLoading(false);
                        return;
                    }
                } else if (!cvLink) {
                    toast.error('Please select a CV.');
                    setIsLoading(false);
                    return;
                }

                // Add CV video file if selected and validate its type and size
                if (cvVideo) {
                    if (['video/mp4', 'video/quicktime', 'video/x-msvideo'].includes(cvVideo.type)) {
                        if (cvVideo.size > 52428800) { // 50MB size limit
                            toast.error('Video file size exceeds the limit of 50MB.');
                            setIsLoading(false);
                            return;
                        }
                        formData.append('cvVideo', cvVideo);
                    } else {
                        toast.error('Only MP4, MOV, and AVI files are allowed for videos!');
                        setIsLoading(false);
                        return;
                    }
                } 

                // Add profile image if selected and validate its type and size
                if (profileImage) {
                    if (['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(profileImage.type)) {
                        if (profileImage.size > 5242880) { // 5MB size limit
                            toast.error('Image file size exceeds the limit of 5MB.');
                            setIsLoading(false);
                            return;
                        }
                        formData.append('profileImage', profileImage);
                    } else {
                        toast.error('Only JPEG, JPG, PNG, and GIF images are allowed for profile images!');
                        setIsLoading(false);
                        return;
                    }
                } else if (!profileImageLink) {
                    toast.error('Please select a profile image.');
                    setIsLoading(false);
                    return;
                }

                const response = await fetch(`${backendURL}/api/user/update-user/${userId}`, {
                    method: 'PUT',
                    body: formData,
                });

                setIsLoading(false);

                if (response.ok) {
                    navigate('/onboarding2');
                } else {
                    const errorMessage = await response.text();
                    try {
                        const errorData = JSON.parse(errorMessage);
                        if (errorData.response) {
                            toast.error(errorData.response); // Set error message state
                        } else {
                            toast.error('An error occurred. Please try again.'); // Set generic error message state if response doesn't contain error message
                        }
                    } catch (error) {
                        toast.error('An error occurred. Please try again.'); // Set generic error message state if unable to parse response
                    }
                }
            } catch (error) {
                setIsLoading(false);
                console.error('Profile update failed:', error);
                toast.error('Profile update failed. Please try again later.');
            }
        }
    };

    const handleFileChange = (e, setFile, fileType) => {
        const file = e.target.files[0];
    
        // Check if a file is selected
        if (!file) {
            // toast.error('No file selected.');
            console.log("no file selected");
            return;
        }
    
        // Check file size
        const maxSizeCV = 10485760; // 10MB for CV
        const maxSizeVideo = 52428800; // 50MB for video
        const maxSizeImage = 5242880; // 5MB for image
    
        if (fileType === 'cv' && file.size > maxSizeCV) {
            toast.error('PDF file size exceeds the limit of 10MB.');
            return;
        }
        if (fileType === 'cvVideo' && file.size > maxSizeVideo) {
            toast.error('Video file size exceeds the limit of 50MB.');
            return;
        }
        if (fileType === 'profileImage' && file.size > maxSizeImage) {
            toast.error('Image file size exceeds the limit of 5MB.');
            return;
        }
    
        const xhr = new XMLHttpRequest();
    
        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                const percentCompleted = Math.round((event.loaded / event.total) * 100);
                setUploadProgress(percentCompleted);
            }
        });
    
        xhr.upload.addEventListener('loadstart', () => {
            setIsLoading(true);
            setUploadingFileType(fileType);
        });
    
        xhr.upload.addEventListener('loadend', () => {
            setIsLoading(false);
            setUploadingFileType('');
        });
    
        xhr.open('POST', `${backendURL}/api/upload`, true);
        const formData = new FormData();
        formData.append(fileType, file);
        xhr.send(formData);
    
        setFile(file);
    
        if (fileType === 'cv') {
            setErrors((prevErrors) => ({ ...prevErrors, cv: null }));
        } else if (fileType === 'cvVideo') {
            setErrors((prevErrors) => ({ ...prevErrors, cvVideo: null }));
        } else if (fileType === 'profileImage') {
            setErrors((prevErrors) => ({ ...prevErrors, profileImage: null }));
        }
    };
    

    return (
        <div className="profile-form">
            <Helmet>
                <title>Tencil - User profile registration</title>
            </Helmet>
            <ToastContainer />
            {isLoading && (
                <div className="loader-overlay">
                    <div className="loader-overlay">
                        <div className='loader'/> {/* Customize loader as needed */}
                        <div className="loader-overlay">
                            Uploading {uploadingFileType}: {uploadProgress}%
                        </div>
                    </div>
                </div>
            )}
            <h2>Fill out your profile</h2>
            <div className="profile-picture-u">
                <label htmlFor="profile-pic-input">
                    {profileImage ? (
                        <img src={URL.createObjectURL(profileImage)} alt="Profile" />
                    ) : profileImageLink ? (
                        <img src={`${backendURL}/profileImages/${profileImageLink}`} alt="Profile" />
                    ) : (
                        <img src="man.png" alt="Profile" />
                    )}
                    <div className="add-icon-u">+</div>
                </label>
                <input
                    type="file"
                    id="profile-pic-input"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, setProfileImage, 'profileImage')}
                />
            </div>
            {!profileImage && !profileImageLink && (
                <p style={{ color: 'lightgray', fontSize: '15px' }}>*Only JPEG, JPG, PNG, and GIF images are allowed for profile image!</p>
            )}
            <div className="upload-prompt">Upload a photo</div>
            <div className="input-group">
                <input type="text" placeholder="First Name" value={firstName} /><br />
                <input type="text" placeholder="Last Name" value={lastName} /><br />
                <input
                    type="text"
                    value={location}
                    onChange={(e) => { setLocation(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, location: null })); }}
                    placeholder="Find Location"
                />
                {errors.location && <div className="error-message">{errors.location}</div>}
                <br />
                <input type="email" placeholder="Email" value={email} /><br />
                <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => { setPhoneNumber(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: null })); }}
                    placeholder="Phone Number"
                />
                {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
                <br />
            </div>
            <div className="upload-group">
                {cvLink && <p>Uploaded CV Link: <a href={`${backendURL}/cvPDFS/${cvLink}`} target="_blank" rel="noopener noreferrer">{cvLink}</a></p>}
                {cv ? (
                    <>
                        <label htmlFor="cv" style={{ color: 'black' }} className="file-preview">
                            <i style={{ color: 'red' }} className="fa-solid fa-file-pdf"></i> {cv.name}
                        </label>
                        <input type="file" id="cv" accept=".pdf" onChange={(e) => handleFileChange(e, setCv, 'cv')} /><br />
                    </>
                ) : (
                    <>
                        <label htmlFor="cv"><i className="fa-solid fa-upload"></i><br />Upload your CV</label>
                        <input type="file" id="cv" accept=".pdf" onChange={(e) => handleFileChange(e, setCv, 'cv')} /><br />
                    </>
                )}
                {!cv && !cvLink && <p style={{ color: 'lightgray', fontSize: '15px' }}>*Only PDF files are allowed for CV!</p>}
                {cvVideoLink && <p>Uploaded CV Video Link: <a href={`${backendURL}/cvVIDEOS/${cvVideoLink}`} target="_blank" rel="noopener noreferrer">{cvVideoLink}</a></p>}
                {cvVideo ? (
                    <>
                        <label htmlFor="video-cv" style={{ color: 'black' }} className="file-preview">
                            <i style={{ color: 'red' }} className="fa-solid fa-circle-play"></i> {cvVideo.name}
                        </label>
                        <input type="file" id="video-cv" accept="video/*" onChange={(e) => handleFileChange(e, setCvVideo, 'cvVideo')} />
                    </>
                ) : (
                    <>
                        <label htmlFor="video-cv"><i className="fa-solid fa-upload"></i><br />Upload your Video CV</label>
                        <input type="file" id="video-cv" accept="video/*" onChange={(e) => handleFileChange(e, setCvVideo, 'cvVideo')} />
                    </>
                )}
                {!cvVideo && !cvVideoLink && <p style={{ color: 'lightgray', fontSize: '15px' }}>*Only MP4, MOV, and AVI files are allowed for Video CV</p>}
            </div>
            <button onClick={handleSubmit} className="save-btn">Save Changes</button>
        </div>
    );
}

export default Onboarding;
