// AddPasswordPage.js
import React, { useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function AddPasswordPage() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const { email ,token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setPasswordMatchError('Passwords do not match');
            return;
        }

        try {
            const response = await fetch(`${backendURL}/api/user/add-password/${email}/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Log success message
                navigate('/userlogin');
            } else {
                const errorMessage = await response.text();
                try {
                    const errorData = JSON.parse(errorMessage);
                    if (errorData.response) {
                        toast.error(errorData.response); // Set error message state
                    } else {
                        toast.error('An error occurred. Please try again.'); // Set generic error message state if response doesn't contain error message
                    }
                } catch (error) {
                    if (errorMessage.includes("User already registered")) {
                        toast.error("User already registered. Please login instead."); // Display specific error message if user is already registered with password
                    } else {
                        toast.error('An error occurred. Please try again.'); // Set generic error message state if unable to parse response
                    }
                }
            }
        }  catch (error) {
            console.error('Error adding password:', error);
        }
    };

    return (
        <div className="add-password-container">
            <ToastContainer />
            <Helmet>
                <title>Tencil - Create a password</title>
            </Helmet>
            <h2>Add Your Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group-password">
                    <label>Password:</label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <div className="form-group-password">
                    <label>Confirm Password:</label>
                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    {passwordMatchError && <p className="error-message">{passwordMatchError}</p>}
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default AddPasswordPage;
