
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joi from 'joi'; // Import Joi for input validation
import { Helmet } from 'react-helmet';

const BusinessProfile = () => {
 
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    businessImage: '',
    location: '',
    phone_no: '',
    email: '',
    b_name: '',
    date: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      setFormData((prevData) => ({
        ...prevData,
        email,
      }));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { businessImage, location, phone_no, email, b_name, date } = formData;

    const tlds = ['com', 'net', 'org']; // Define a list of allowed top-level domains

          // Define Joi schema for input validation
           const schema = Joi.object({
            phone_no: Joi.string().trim().pattern(new RegExp('^[0-9]{10}$')).required().messages({
              'string.pattern.base': 'Phone number should be exactly 10 digits'
            }),
            location: Joi.string().trim().required(),
            email: Joi.string().trim().email({ tlds: { allow: tlds } }).required(),
            b_name: Joi.string().trim().required(),
            date: Joi.date().iso().required(),
            businessImage: Joi.any() // Allow any type for profileImage
          });

    // Validate input data
    const { error } = schema.validate(formData, { abortEarly: false });

    // If validation fails, display error messages
    if (error) {
      const validationErrors = {};
      error.details.forEach((item) => {
        validationErrors[item.path[0]] = item.message;
        // toast.error(item.message);
      });
      setErrors(validationErrors);
      return;
    } else {
      try {
        const formData = new FormData();
        formData.append('phone_no', phone_no);
        formData.append('location', location);
        formData.append('email', email);
        formData.append('b_name', b_name);
        formData.append('date', date);

        // Add profile image if selected and validate its type
        if (businessImage) {
          if (['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(businessImage.type)) {
            formData.append('businessImage', businessImage);
          } else {
            toast.error('Only JPEG, JPG, PNG, and GIF images are allowed for business images!');
            return;
          }
        }
        // Check if any of the required fields are empty
        if (!businessImage) {
          toast.error('Please select business image.');
          return;
        }

        const response = await fetch(`${backendURL}/api/business/update-user`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          toast.success('Profile updated successfully!');
          localStorage.clear();
          setTimeout(() => {
            navigate('/businesslogin');
        }, 1000);
        } else {
          const errorMessage = await response.text();
          try {
            const errorData = JSON.parse(errorMessage);
            if (errorData.error) {
              toast.error(errorData.error);
            } else {
              toast.error('An error occurred. Please try again.');
            }
          } catch (error) {
            toast.error('An error occurred. Please try again.');
          }
        }
      } catch (error) {
        console.error('Profile update failed:', error);
        toast.error('Profile update failed. Please try again later.');
      }
    }

  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, businessImage: e.target.files[0] });
  };


  return (
    <div>
    <Helmet>
        <title>Tencil-Business Profile</title>
    </Helmet>
    <ToastContainer />
    <div className='profile'>
      <div style={{ fontSize: "40px" }}>Fill out your Business Profile</div>
      <div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="profile-picture">
            <label htmlFor="file-upload">
            <img src={formData.businessImage ? URL.createObjectURL(formData.businessImage) : "man.png"} alt="Profile" />
            <div className="add-icon">+</div></label>
            <input id="file-upload" type="file" accept="image/*" onChange={handleImageChange} style={{ display: "none" }} />
          </div>
          <p>
            Upload a photo
          </p>
          <input type='text' name='b_name' value={formData.b_name} onChange={handleChange} placeholder='Full Business Name' /><br />
          {errors.b_name && <div className="error">{errors.b_name}</div>}<br />
          <input type='date' name='date' value={formData.date} onChange={handleChange} placeholder='Business start Date' /><br />
          {errors.date && <div className="error">{errors.date}</div>}<br />
          <input type='text' name='location' value={formData.location} onChange={handleChange} placeholder='Find Location' /><br />
          {errors.location && <div className="error">{errors.location}</div>}<br />
          <input type='email' name='email' value={formData.email} onChange={handleChange} placeholder='Business Email' readOnly /><br />
          {errors.email && <div className="error">{errors.email}</div>}<br />
          <input type='number' name='phone_no' value={formData.phone_no} onChange={handleChange} placeholder='Business Phone Number' /><br />
          {errors.phone_no && <div className="error">{errors.phone_no}</div>}<br />
          <button type='submit'>Save Changes</button>
        </form>
      </div>
    </div>
    </div>
  )
}


export default BusinessProfile



