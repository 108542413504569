import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Dropdown = ({ label, options, isOpen, toggleDropdown, handleOptionClick }) => (
    <div className="cv-dropdown">
        <Helmet>
                <title>Tencil - create your CV writer</title>
            </Helmet>
        <div className="dropbtn" onClick={toggleDropdown}>
            <span>{label}</span>
            <i className={`${isOpen ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'}`}></i>
        </div>
        {isOpen && (
            <div className="dropdown-options">
                {options.map((option, index) => (
                    <div
                        key={index}
                        onClick={() => handleOptionClick(option)}
                        className={`cv-dropdown-content ${option.className ? option.className : ''}`}
                    >
                        {option.text}
                    </div>
                ))}
            </div>
        )}
    </div>
);


const CVWriter = ({ collapsed, toggleSidebar }) => {
    const [isOpen, setIsOpen] = useState({ today: false, date5: false, date1: false });
    const options = {
        today: [
            { text: 'CV Tips', action: () => console.log('Option 1 clicked') },
            { text: 'Job Security Tips', action: () => console.log('Option 2 clicked') },
            { text: 'Technical Skills', action: () => window.location.href = '/other-page' },
            { text: 'Career Development', action: () => console.log('Option 1 clicked') },
            { text: 'Networking', action: () => console.log('Option 2 clicked') },
            { text: 'Job Search', action: () => window.location.href = '/other-page' },
            { text: 'Industry Insights', action: () => console.log('Option 2 clicked') }
        ],
        date5: [
            { text: 'CV Tips', action: () => console.log('Option 1 clicked') },
            { text: 'Job Security Tips', action: () => console.log('Option 2 clicked') },
            { text: 'Technical Skills', action: () => window.location.href = '/other-page' },
            { text: 'Career Development', action: () => console.log('Option 1 clicked') },
            { text: 'Networking', action: () => console.log('Option 2 clicked') },
            { text: 'Job Search', action: () => window.location.href = '/other-page' },
            { text: 'Industry Insights', action: () => console.log('Option 2 clicked') }
        ],
        date1: [
            { text: 'CV Tips', action: () => console.log('Option 1 clicked') },
            { text: 'Job Security Tips', action: () => console.log('Option 2 clicked') },
            { text: 'Technical Skills', action: () => window.location.href = '/other-page' },
            { text: 'Career Development', action: () => console.log('Option 1 clicked') },
            { text: 'Networking', action: () => console.log('Option 2 clicked') },
            { text: 'Job Search', action: () => window.location.href = '/other-page' },
            { text: 'Industry Insights', action: () => console.log('Option 2 clicked') }
        ]
    };

    const toggleDropdown = (dropdown) => () => {
        setIsOpen(prevState => ({
            ...prevState,
            [dropdown]: !prevState[dropdown]
        }));
    };

    const handleOptionClick = (option) => {
        option.action(); // Execute the action associated with the option
        setIsOpen({ today: false, date5: false, date1: false });
    };
    return (
        <div className={`cv-writer-container${collapsed ? '' : '-collapsed'}`}>
            <div className="sidebar-c">
                <div className="logo-container-c">
                    <img src="logo.jpeg" alt="TENCIL AI" />
                    <h1>TENCIL AI</h1>
                </div>
                <div>
                    <Dropdown
                        label="Today"
                        options={options.today.map(option => ({
                            ...option,
                            className: option.text === 'Career Development' ? 'Career-Development' : ''
                        }))}
                        isOpen={isOpen.today}
                        toggleDropdown={toggleDropdown('today')}
                        handleOptionClick={handleOptionClick}
                    />

                    <Dropdown
                        label="5/12/23"
                        options={options.date5}
                        isOpen={isOpen.date5}
                        toggleDropdown={toggleDropdown('date5')}
                        handleOptionClick={handleOptionClick}
                    />
                    <Dropdown
                        label="1/12/23"
                        options={options.date1}
                        isOpen={isOpen.date1}
                        toggleDropdown={toggleDropdown('date1')}
                        handleOptionClick={handleOptionClick}
                    />
                </div>
            </div>
            <div className="cv-main-content">
                <div className="cv-search-bar">
                    <input type="text" placeholder="Type your questions..." />
                </div>
                <div className="cv-section">
                    <div className="cv-section-header">
                        <h2>Career Development</h2>
                        <img src="logo.jpeg" alt="Small Logo" />
                    </div>
                    <div className="cv-section-content">
                        <div className='cv-section-image'>
                            <img src="register.jpeg" alt="Image 1" />
                        </div>
                        <div className='cv-section-content5'>
                            <h3>john <span>2:00AM</span></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.x</p>
                            <div className='cvwriter-logo'>
                                <div className="cv-section-content4">
                                    <div className='cv-section-image'>
                                        <i className="fa-solid fa-file"></i>
                                    </div>
                                    <div className='cv-section-content2'>
                                        <h3>CV Templates</h3>
                                        <p>CV Template Writer</p>
                                    </div>
                                    <i className="fa-solid fa-cloud-arrow-up"></i>
                                </div>
                            </div>
                            <div className="like">
                                <p>like</p>
                                <p>image</p>
                            </div>
                            <div className="like">
                                <i className="fa-solid fa-heart"></i>
                                <img src="bg4.jpeg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className="cv-section-content">
                        <div className='cv-section-image'>
                            <img src="logo.jpeg" alt="Image 1" />
                        </div>
                        <div className='cv-section-content1'>
                            <h3>technical ai <span>2:00AM</span></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, </p>
                        </div>
                    </div>
                    <div className="cv-section-content">
                        <div className='cv-section-image'>
                            <img src="register.jpeg" alt="Image 1" />
                        </div>
                        <div className='cv-section-content1'>
                            <h3>technical ai <span>2:00AM</span></h3>
                            <p>Lorem ipsum. </p>
                            <div className="like">
                                <i className="fa-solid fa-heart"></i>
                                <img src="bg4.jpeg" alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className="cv-textarea">
                        <textarea rows="4" cols="50" placeholder='type something here ....'></textarea>
                        <div className="cv-icons">
                            <i className="fa-solid fa-paperclip"></i>
                            <button><i className="fa-solid fa-lock"></i> UNLOCK PROBLEM </button>
                            <i className="fa-solid fa-paper-plane"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CVWriter;
