import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function ForgotPasswordUser() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [email, setEmail] = useState('');
    // const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);

        try {
            const response = await axios.post(`${backendURL}/api/user/forgot-password`, { email });
            alert(response.data); // Display the backend response as an alert
        } catch (error) {
            if (error.response && error.response.status === 400) {
                alert('Please complete your registration first');
            } else {
                alert('Please enter a registered email id');
            }
        } finally {
            // setLoading(false);
        }
    };

    return (
        <div className='forgot'>
            {/* {loading && <div className="loader-overlay"><div className="loader"></div></div>} */}
            <Helmet>
                <title>Tencil - Forgot password for user</title>
            </Helmet>
            <h2>Forgot Password</h2>
            <p>Please enter your email address below. We will send you a link to reset your password.</p>
            <form onSubmit={handleSubmit}>
                <input
                    type='email'
                    value={email}
                    style={{ height: "45px", width: "350px" }}
                    placeholder='Enter your email id'
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br /><br /><br />
                <button type="submit">Send Reset Link</button>
                {/* <button type="submit" disabled={loading}>Send Reset Link</button> */}
            </form>
        </div>
    );
}

export default ForgotPasswordUser;
