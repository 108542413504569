import React, { useState } from 'react';
import Joi from 'joi';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BusinessAccount = () => {
    
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        password: '',
        cpassword: ''
    });
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, userName, email, password, cpassword } = formData;

        // const tlds = ['com', 'net', 'org' ]; // Define a list of allowed top-level domains

        // Define custom error messages
        const customMessages = {
            'string.pattern.base': 'Field must contain only letters'
        };

        // Define regex pattern for alphabetic characters
        const alphabeticPattern = /^[a-zA-Z]+$/;

        const schema = Joi.object({
            firstName: Joi.string().trim().allow(null).required().pattern(alphabeticPattern).messages(customMessages),
            lastName: Joi.string().trim().allow(null).required().pattern(alphabeticPattern).messages(customMessages),
            userName: Joi.string().trim().allow(null).required().pattern(alphabeticPattern).messages(customMessages),
            email: Joi.string().email({ tlds: { allow: false } }).trim().allow(null).required(),
            password: Joi.string().trim().allow(null).required().pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!@#$%^&*]{8,}$')).messages({
                'string.pattern.base': 'Password must contain at lowercase, uppercase, number, special charecter and be at least 8 characters long'
              }),
            cpassword: Joi.string().trim().allow(null).required(),
        });

        const { error } = schema.validate(formData, { abortEarly: false });
        if (error) {
            const validationErrors = {};
            error.details.forEach((item) => {
                validationErrors[item.path[0]] = item.message;
                // toast.error(item.message);
            });
            setErrors(validationErrors);
            return;
        }


        if (password !== cpassword) {
            toast.error("Passwords do not match" );
            return;
        }

        // Check if the checkbox is checked
        const agreeCheckbox = document.getElementById("agreeCheckbox");
        if (!agreeCheckbox.checked) {
            toast.error("Please agree to the terms and conditions");
            return;
        }

        setErrors({});

        if (Object.keys({}).length > 0) {
            return;
        }

        try {
            const response = await fetch(`${backendURL}/api/business/create-user`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ firstName, lastName, userName, email, password, cpassword })
            });

            const json = await response.json();
            if (json.message === 'Business registered successfully') {
                 localStorage.setItem('email', email);
                toast.success("Account Created Successfully");
                setTimeout(() => {
                    navigate('/businessprofile');
                }, 1000);
            } else {
                toast.error((json.error || "Invalid form data"));
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
        }


    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    return (
        <div className='acc'>
            <Helmet>
                <title>Tencil - Business Registration</title>
            </Helmet>
            <ToastContainer />
            <div className='acc-1'>Create a Business Account</div>
            <div className='acc-2'>join our community today</div>
            <div className='acc-3'>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name:</label>
                            <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} placeholder='Enter your first name' />
                            {errors.firstName && <div className="error">{errors.firstName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name:</label>
                            <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} placeholder='Enter your last name' />
                            {errors.lastName && <div className="error">{errors.lastName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="userName">Username:</label>
                            <input type="text" id="userName" name="userName" value={formData.userName} onChange={handleChange} placeholder='Enter your username' />
                            {errors.userName && <div className="error">{errors.userName}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder='Enter your Email' />
                            {errors.email && <div className="error">{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} placeholder='Create a password' />
                            {errors.password && <div className="error">{errors.password}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="cpassword">Confirm Password:</label>
                            <input type="password" id="cpassword" name="cpassword" value={formData.cpassword} onChange={handleChange} placeholder='Confirm password' />
                            {errors.cpassword && <div className="error">{errors.cpassword}</div>}
                        </div>
                        <label htmlFor="agreeCheckbox" style={{ fontSize: "15px" }}>
                            <input type="checkbox" style={{ width: '15px', height: '15px' }} id="agreeCheckbox" />I agree to the terms and conditions and privacy policy</label>
                        <button type="submit">Register</button>
                        <div style={{ marginTop: "10px" }}>already an account? <a href='/businesslogin'>Login</a></div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default BusinessAccount


