import React from 'react';
import { Helmet } from 'react-helmet';

const CompanyProfile = ({ collapsed, toggleSidebar }) => {
    return (
        <div className={`company-profile-container${collapsed ? '' : '-collapsed'}`}>
        <Helmet>
            <title>Tencil - Company profile</title>
        </Helmet>
            <div className="image-container">
                <img src="tesla.jpeg" alt="Company Logo" className="company-logo-p" />
                <div className="company-details">
                    <h1 className="company-name">Tesla</h1>
                    <p className="company-description">Electric Vehicle Company</p>
                    <div className="additional-info">
                        <p className="follower-count"><strong>1000+</strong> followers</p>
                        <p className="open-positions"><strong>10</strong> open positions</p>
                    </div>
                    <div className="buttons">
                        <button className="follow-btn">Follow</button>
                        <button className="visit-btn">Visit Website</button>
                    </div>
                </div>
            </div>
            <div className="navigation">
                <div className="nav-item">Company</div>
                <div className="nav-item">Positions</div>
                <div className="nav-item">Company News</div>
                <div className="nav-item">Company Videos</div>
            </div>
            <div className="image-grid">
                <img src="register.jpeg" alt="Image 1" className="grid-image" />
                <img src="back.jpeg" alt="Image 2" className="grid-image" />
                <img src="bg4.jpeg" alt="Image 3" className="grid-image" />
                <img src="bg4.jpeg" alt="Image 4" className="grid-image" />
                <img src="back.jpeg" alt="Image 5" className="grid-image" />
                <img src="register.jpeg" alt="Image 6" className="grid-image" />
            </div>
        </div>
    );
}

export default CompanyProfile;
