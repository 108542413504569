import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Post from './Post';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';

Modal.setAppElement('#root');

const Search = ({ collapsed, toggleSidebar }) => {
    const videoRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [noMatchFound, setNoMatchFound] = useState(false);
    const [activeTab, setActiveTab] = useState('topResults'); // State to track active tab
    const [loading, setLoading] = useState(false); // Loading state
    const [loadingMore, setLoadingMore] = useState(false); // Loading state for more posts
    const typingTimeoutRef = useRef(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const debounce = (func, delay) => {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(func, delay);
    };

    const fetchAllPosts = async (query) => {
        try {
            // setLoading(true);
             // Set loading to true when starting to fetch posts

            const initialResponse = await axios.get(`${backendURL}/api/post/posts`, {
                params: { search: query, page: 1, limit: 5 } // Limit to 5 posts initially
            });

            const totalPosts = initialResponse.data.data.totalPages * 5;
            const limit = 5;
            const totalPages = Math.ceil(totalPosts / limit);

            let allPosts = [];
            for (let page = 1; page <= totalPages; page++) {
                const response = await axios.get(`${backendURL}/api/post/posts`, {
                    params: { search: query, page, limit }
                });
                allPosts = allPosts.concat(response.data.data.posts);
            }

            // setLoading(false);
            // Set loading to false after fetching all posts

            return allPosts;
        } catch (error) {
            console.error("Error fetching posts:", error);
            // setLoading(false); // Ensure loading state is set to false on error
            return [];
        }
    };

    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        debounce(async () => {
            if (query === '') {
                setFilteredPosts([]);
                setNoMatchFound(false);
                return;
            }
 
            try {
                // setLoading(true); // Set loading to true when starting to search
                const posts = await fetchAllPosts(query);

                const userIds = [...new Set(posts.map(post => post.userId))];
                const usersData = await Promise.all(
                    userIds.map(async userId => {
                        const userResponse = await axios.get(`${backendURL}/api/user/fetch/${userId}`);
                        return { userId, ...userResponse.data };
                    })
                );

                const usersMap = usersData.reduce((acc, user) => {
                    acc[user.userId] = user;
                    return acc;
                }, {});

                const filtered = posts
                    .filter(post =>
                        post.postContent.toLowerCase().includes(query.toLowerCase())
                    )
                    .map(post => ({
                        ...post,
                        userData: usersMap[post.userId] // Attach user data to each post
                    }));

                setFilteredPosts(filtered);
                setNoMatchFound(filtered.length === 0);
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                // setLoading(false); // Set loading to false after search results are fetched
            }
        }, 1000);
    };

    const handleTabChange = async (tab) => {
        setActiveTab(tab); // Update active tab
        // setLoading(true); // Set loading to true when tab changes
        await fetchAllPosts(searchQuery); // Fetch posts for the active tab
        // setLoading(false); // Set loading to false after posts are fetched
    };

    const getLatestPosts = (posts) => {
        const now = new Date();
        return posts.filter(post => {
            const postDate = new Date(post.createdAt); // Assuming posts have a `createdAt` field
            const thirtyMinutesAgo = new Date(now.getTime() - 31 * 60 * 1000);
            return postDate >= thirtyMinutesAgo;
        });
    };

    useEffect(() => {
        handleTabChange(activeTab); // Fetch posts when component mounts
    }, []);

    return (
        <div className={`search-container${collapsed ? '' : '-collapsed'}`}>
        <Helmet>
            <title>Tencil - search your needs</title>
        </Helmet>
            <input type="text" className="search-bar" placeholder="Search" value={searchQuery} onChange={handleSearch} />

            {loading ? (
                <div className="loader"></div> // Display loader when loading is true
            ) : noMatchFound ? (
                <div className="no-match-found">
                    No match found
                </div>
            ) : (
                filteredPosts.length > 0 ? (
                    <div className="search-result">
                        <div className="top-results">
                            <div className={`tab ${activeTab === 'topResults' ? 'active' : ''}`} onClick={() => handleTabChange('topResults')}>Top results</div>
                            <div className={`tab ${activeTab === 'latest' ? 'active' : ''}`} onClick={() => handleTabChange('latest')}>Latest</div>
                            <div className={`tab ${activeTab === 'media' ? 'active' : ''}`} onClick={() => handleTabChange('media')}>Media</div>
                        </div>
                        {activeTab === 'topResults' && (
                            <div className="search-sections">
                                {filteredPosts.slice(0, 5).map(post => (
                                    <Post key={post._id} post={post} userData={post.userData} backendURL={backendURL} />
                                ))}
                                {loadingMore && (
                                    <div className="loader"></div> // Display loader after initial 5 posts
                                )}
                                {filteredPosts.slice(5).map(post => (
                                    <Post key={post._id} post={post} userData={post.userData} backendURL={backendURL} />
                                ))}
                            </div>
                        )}
                        {activeTab === 'latest' && (
                            <div className="search-sections">
                                {getLatestPosts(filteredPosts).map(post => (
                                    <Post key={post._id} post={post} userData={post.userData} backendURL={backendURL} />
                                ))}
                            </div>
                        )}
                        {activeTab === 'media' && (
                            <div className="media-content">
                                {/* Add media content here */}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="video-section-search">
                        <h2 className="video-title">Videos for You</h2>
                        <div className="video-container">
                            <video controls>
                                <source src="demo2.webm" type="video/mp4" />
                            </video>
                            <video controls>
                                <source src="demo2.webm" type="video/mp4" />
                            </video>
                            <video controls>
                                <source src="demo2.webm" type="video/mp4" />
                            </video>
                        </div>

                        <div className="other-sections">
                            <div className="other-item">
                                <div className="user-text" style={{ marginBottom: '10px' }}>
                                    <div className='main-title'>Businesses</div>
                                    <div className='main-description'>50k phases</div>
                                </div>
                                <div className="user-text">
                                    <div className='main-description'>Trading is your area</div>
                                    <div className='main-title'>tech startups</div>
                                    <div className='main-description'>100k phases</div>
                                </div>
                            </div>
                            <div className="other-item">
                                <div className="">
                                    <h2>Featured Businesses</h2>
                                    <div className="business-info">
                                        <div className="user-info">
                                            <img src="providence.png" alt="User" className="business-image" />
                                            <div className="user-text">
                                                <div>providence web services</div>
                                                <div>providenceservice1234</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="business-info">
                                        <div className="user-info">
                                            <img src="tesla.jpeg" alt="User" className="business-image" />
                                            <div className="user-text">
                                                <div>Tesla</div>
                                                <div>axis</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="other-item">
                                <div className="featured-business-s">
                                    <h2>who to follow</h2>
                                    <div className="business-info">
                                        <div className="user-info">
                                            <img src="providence.png" alt="User" className="business-image" />
                                            <div className="user-text">
                                                <div>providence web services</div>
                                                <div>providenceservice1234</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="business-info">
                                        <div className="user-info">
                                            <img src="tesla.jpeg" alt="User" className="business-image" />
                                            <div className="user-text">
                                                <div>tesla</div>
                                                <div>axis</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default Search;
