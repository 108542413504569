import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import '../css/Home.css';
import '../css/Guest.css';
import '../css/Auth.css'; // Import the shared CSS file
import StartScreen from './StartScreen';
import Business from './Business';
import BusinessLogin from './BusinessLogin';
import BusinessAccount from './BusinessAccount';
import BusinessProfile from './BusinessProfile';
import Onboarding from './Onboarding';
import Register from './Register';
import Onboarding2 from './Onboarding2';
import Onboarding3 from './Onboarding3';
import Userportal from './Userportal';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Search from './Search';
import CompanyProfile from './CompanyProfile';
import EditProfile from './EditProfile';
import CVWriter from './CVWriter';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import UserLogin from './UserLogin';
import AddPasswordPage from './AddPasswordPage';
import ThankYouPage from './ThankYouPage';
import ForgotPasswordUser from './ForgotPasswordUser';
import ResetPasswordUser from './ResetPasswordUser';
import CreatePost from './CreatePost';

const OnboardingRoute = ({ children }) => {
  const hasStartedRegistration = !!sessionStorage.getItem('userId'); // Check if the registration has started

  return hasStartedRegistration ? children : <Navigate to="/register" />;
};

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('token'); 

  return isAuthenticated ? children : <Navigate to="/userlogin" />;
};

function Guest() {
  const [collapsed, setCollapsed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<StartScreen />} />
          <Route exact path="/business" element={<Business />} />
          <Route exact path="/userportal" element={<Userportal />} />
          <Route exact path="/businesslogin" element={<BusinessLogin />} />
          <Route exact path="/userlogin" element={<UserLogin />} />
          <Route exact path="/businessaccount" element={<BusinessAccount />} />
          <Route exact path="/businessprofile" element={<BusinessProfile />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/onboarding" element={<OnboardingRoute><Onboarding /></OnboardingRoute>} />
          <Route exact path="/onboarding2" element={<OnboardingRoute><Onboarding2 /></OnboardingRoute>} />
          <Route exact path="/onboarding3" element={<OnboardingRoute><Onboarding3 /></OnboardingRoute>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password-user" element={<ForgotPasswordUser />} />
          <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
          <Route path="/reset-password-user/:token/:email" element={<ResetPasswordUser />} />
          <Route path="/add-password/:token/:email" element={<AddPasswordPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/dashboard" element={<PrivateRoute><><Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> <Dashboard collapsed={collapsed} toggleSidebar={toggleSidebar} /></></PrivateRoute>} />
          <Route path="/search" element={<PrivateRoute><><Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> <Search collapsed={collapsed} toggleSidebar={toggleSidebar} /></></PrivateRoute>} />
          <Route path="/editprofile" element={<PrivateRoute><><Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> <EditProfile collapsed={collapsed} toggleSidebar={toggleSidebar} /></></PrivateRoute>} />
          <Route path="/companyprofile" element={<PrivateRoute><><Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> <CompanyProfile collapsed={collapsed} toggleSidebar={toggleSidebar} /></></PrivateRoute>} />
          <Route path="/cvwriter" element={<PrivateRoute><><Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> <CVWriter collapsed={collapsed} toggleSidebar={toggleSidebar} /></></PrivateRoute>} />
          <Route path="/createpost" element={<PrivateRoute><><Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> <CreatePost collapsed={collapsed} toggleSidebar={toggleSidebar} /></></PrivateRoute>} />
        </Routes>
      </Router>
    </>
  );
}

export default Guest;
