import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Post from './Post';
import { Helmet } from 'react-helmet';

Modal.setAppElement('#root');

const Dashboard = ({ collapsed, toggleSidebar }) => {
  const [fullName, setFullName] = useState('');
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState({});
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const initialFetchDone = useRef(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      fetchUserData(userId);
    }
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`${backendURL}/api/user/fetch/${userId}`);
      if (response.ok) {
        const userData = await response.json();
        setFullName(userData.fullName || '');
      } else {
        console.error('Failed to fetch user data.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchPosts = async (page = 1) => {
    try {
      const response = await axios.get(`${backendURL}/api/post/posts`, {
        params: { page, limit: 5 }
      });
      const { posts: newPosts, totalPages } = response.data.data;

      setPosts((prevPosts) => {
        const postIds = new Set(prevPosts.map(post => post._id));
        const uniqueNewPosts = newPosts.filter(post => !postIds.has(post._id));
        return [...prevPosts, ...uniqueNewPosts];
      });
      setCurrentPage(page);
      setHasMore(page < totalPages);

      const userIds = [...new Set(newPosts.map(post => post.userId))];
      const usersData = await Promise.all(
        userIds.map(async userId => {
          const userResponse = await axios.get(`${backendURL}/api/user/fetch/${userId}`);
          return { userId, ...userResponse.data };
        })
      );

      const usersMap = usersData.reduce((acc, user) => {
        acc[user.userId] = user;
        return acc;
      }, {});
      setUsers((prevUsers) => ({ ...prevUsers, ...usersMap }));
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to load posts');
    } finally {
      // setLoading(false);
      setLoadMoreLoading(false);
    }
  };

  useEffect(() => {
    if (!initialFetchDone.current) {
      fetchPosts();
      initialFetchDone.current = true;
    }
  }, [backendURL]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 && hasMore && !loadMoreLoading) {
        loadMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, loadMoreLoading]);

  const loadMorePosts = () => {
    setLoadMoreLoading(true);
    fetchPosts(currentPage + 1);
  };

  return (
    <div className={`dashboard${collapsed ? '' : '-collapsed'}`}>
      <Helmet>
        <title>Tencil - user dashboard</title>
      </Helmet>
      <h2>Good Afternoon! {fullName}</h2>
      <h1>Browse What's Happening Today!</h1>

      {/* <div className="posts-section">
        {loading ? (
          <div className="loader"></div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <>
            {posts.map(post => (
              <Post key={post._id} post={post} userData={users[post.userId]} backendURL={backendURL} />
            ))}
            {loadMoreLoading && (
              <div className="loader"></div>
            )}
          </>
        )}
      </div> */}

      <div className="posts-section">
        {posts.map(post => (
          <Post key={post._id} post={post} userData={users[post.userId]} backendURL={backendURL} />
        ))}
        {loadMoreLoading && (
          <div className="loader"></div>
        )}
      </div>

      <div className="featured-business">
        <h2>Featured Businesses</h2>
        <div className="business-info">
          <div className="user-info">
            <img src="providence.png" alt="User" className="business-image" />
            <div className="user-text">
              <div>providence web services</div>
              <div>providenceservice1234</div>
            </div>
            <i className="fa-solid fa-caret-right"></i>
          </div>
        </div>
        <div className="business-info">
          <div className="user-info">
            <img src="tesla.jpeg" alt="User" className="business-image" />
            <div className="user-text">
              <div>tesla luxury cars</div>
              <div>teslaluxurycars</div>
            </div>
            <i className="fa-solid fa-caret-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
