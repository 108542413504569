import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const CreatePost = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [postContent, setPostContent] = useState('');
  const [postImages, setPostImages] = useState([]);
  const [postVideos, setPostVideos] = useState([]);
  const [isLeftButtonVisible, setIsLeftButtonVisible] = useState(false);
  const [isRightButtonVisible, setIsRightButtonVisible] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for post submission
  const [fileLoading, setFileLoading] = useState(false); // Loading state for file selection
  const fileInputRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      fetchUserProfile(userId);
    }
  }, []);

  const fetchUserProfile = async (userId) => {
    try {
      const response = await axios.get(`${backendURL}/api/user/fetch/${userId}`);
      if (response.status === 200) {
        setUserProfileImage(response.data.profileImage);
      } else {
        console.error('Failed to fetch user profile image.');
      }
    } catch (error) {
      console.error('Error fetching user profile image:', error);
    }
  };

  const handlePostChange = (e) => {
    setPostContent(e.target.value);
  };

  const handlePostSubmit = async () => {
    const userId = localStorage.getItem('userId');
    const formData = new FormData();
    formData.append('postContent', postContent);
    formData.append('userId', userId);

    for (const { file } of postImages) {
      if (['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)) {
        formData.append('postImages', file);
      } else {
        toast.error('Only JPEG, JPG, PNG, and GIF images are allowed for profile images!');
        return;
      }
    }

    for (const { file } of postVideos) {
      if (['video/mp4', 'video/quicktime', 'video/x-msvideo'].includes(file.type)) {
        formData.append('postVideos', file);
      } else {
        toast.error('Only MP4, MOV, and AVI files are allowed for videos!');
        return;
      }
    }

    setLoading(true); // Start loading
    setIsUploading(true); // Start upload progress

    try {
      await axios.post(`${backendURL}/api/post/createposts`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      setPostContent('');
      setPostImages([]);
      setPostVideos([]);
      console.log('Post created successfully');
    } catch (error) {
      toast.error('Error creating post. Please try again later.');
      console.error('Error creating post:', error);
    } finally {
      setLoading(false); // Stop loading
      setIsUploading(false); // Stop upload progress
      setUploadProgress(0); // Reset progress
    }
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // const handleFileChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   const imageFiles = files.filter(file => file.type.startsWith('image/'));
  //   const videoFiles = files.filter(file => file.type.startsWith('video/'));

  //   const filePreviews = (fileArray) => {
  //     return fileArray.map((file) => {
  //       return new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           resolve({ file, preview: reader.result });
  //         };
  //         reader.onerror = reject;
  //         reader.readAsDataURL(file);
  //       });
  //     });
  //   };

  //   // Check for image size
  //   const imageFilesExceedingSizeLimit = imageFiles.filter(file => file.size > 5 * 1024 * 1024); // 5MB in bytes
  //   if (imageFilesExceedingSizeLimit.length > 0) {
  //     toast.error('Image file size exceeds the limit of 5MB.');
  //     return;
  //   }

  //   setFileLoading(true); // Start file loading

  //   Promise.all(filePreviews(imageFiles)).then((filePreviewData) => {
  //     setPostImages((prevFiles) => [...prevFiles, ...filePreviewData]);
  //     setFileLoading(false); // Stop file loading
  //   });

  //   Promise.all(filePreviews(videoFiles)).then((filePreviewData) => {
  //     setPostVideos((prevFiles) => [...prevFiles, ...filePreviewData]);
  //     setFileLoading(false); // Stop file loading
  //   });
  // };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    const videoFiles = files.filter(file => file.type.startsWith('video/'));
  
    const filePreviews = (fileArray) => {
      return fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ file, preview: reader.result });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
    };
  
    const checkFileSize = (file, maxSize, type) => {
      if (file.size > maxSize) {
        const fileSizeInMB = maxSize / (1024 * 1024);
        const fileType = type === 'image' ? 'Image' : 'Video';
        toast.error(`${fileType} file size exceeds the limit of ${fileSizeInMB}MB.`);
        return false;
      }
      return true;
    };
  
    setFileLoading(true); // Start file loading
  
    Promise.all(filePreviews(imageFiles)).then((filePreviewData) => {
      const validImages = filePreviewData.filter(({ file }) => checkFileSize(file, 5 * 1024 * 1024, 'image'));
      setPostImages((prevFiles) => [...prevFiles, ...validImages]);
      setFileLoading(false); // Stop file loading
    });
  
    Promise.all(filePreviews(videoFiles)).then((filePreviewData) => {
      const validVideos = filePreviewData.filter(({ file }) => checkFileSize(file, 50 * 1024 * 1024, 'video'));
      setPostVideos((prevFiles) => [...prevFiles, ...validVideos]);
      setFileLoading(false); // Stop file loading
    });
  };
  

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
      const scrollAmount = clientWidth;
      const newScrollPosition = direction === 'left' ? scrollLeft - scrollAmount : scrollLeft + scrollAmount;

      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });

      setIsLeftButtonVisible(newScrollPosition > 0);
      setIsRightButtonVisible(newScrollPosition + clientWidth < scrollWidth);
    }
  };

  const updateButtonVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
      setIsLeftButtonVisible(scrollLeft > 0);
      setIsRightButtonVisible(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const handleRemove = (indexToRemove, type) => {
    if (type === 'image') {
      setPostImages((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
    } else if (type === 'video') {
      setPostVideos((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
    }
  };

  const Loader = ({ progress }) => (
    <div className="loader-overlay">
      <div className="loader"></div>
      {progress !== undefined && (
        <div className="loader-progress">
          {progress}%
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth;
      const itemWidth = containerWidth / 2;
      const items = scrollContainerRef.current.querySelectorAll('.mediaPreview-item');
      items.forEach(item => {
        item.style.minWidth = `${itemWidth}px`;
      });

      updateButtonVisibility();
    }
  }, [postImages, postVideos]);

  return (
    <div className="postBox">
      <Helmet>
        <title>Tencil - Create your posts</title>
      </Helmet>
      <ToastContainer />
      {(loading || fileLoading) && <Loader progress={isUploading ? uploadProgress : undefined} />} {/* Display loader with progress */}
      {/* {loading && <Loader />} 
      {fileLoading && <Loader />}  */}
      <div className="postBox-header">
        {userProfileImage ? (
          <img src={`${backendURL}/profileImages/${userProfileImage}`} alt="User" className="user-image" />
        ) : (
          <p>Placeholder</p>
        )}
      </div>
      <textarea
        className="postBox-textarea"
        placeholder="What is happening?!"
        value={postContent}
        onChange={handlePostChange}
        disabled={loading || fileLoading} // Disable textarea when loading
      />
      {(postImages.length > 0 || postVideos.length > 0) && (
        <div className="postBox-mediaPreviewContainer">
          {isLeftButtonVisible && (
            <button className="scrollButton left" onClick={() => handleScroll('left')}>‹</button>
          )}
          <div
            className="postBox-mediaPreview"
            ref={scrollContainerRef}
            onScroll={updateButtonVisibility}
            style={{ display: 'flex', overflowX: 'auto' }}
          >
            {postImages.map(({ file, preview }, index) => (
              <div key={index} className="mediaPreview-item" style={{ position: 'relative', marginRight: '10px' }}>
                <img src={preview} alt="Selected" className="mediaPreview-image" />
                <button
                  className="removeButton"
                  style={{ position: 'absolute', top: '5px', left: '5px', background: 'transparent', border: 'none', cursor: 'pointer' }}
                  onClick={() => handleRemove(index, 'image')}
                >
                  <i className="fa-solid fa-xmark" style={{ color: 'white', fontSize: '20px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                </button>
              </div>
            ))}
            {postVideos.map(({ file, preview }, index) => (
              <div key={index} className="mediaPreview-item" style={{ position: 'relative', marginRight: '10px' }}>
                <video src={preview} controls className="mediaPreview-video" />
                <button
                  className="removeButton"
                  style={{ position: 'absolute', top: '5px', left: '5px', background: 'transparent', border: 'none', cursor: 'pointer' }}
                  onClick={() => handleRemove(index, 'video')}
                >
                  <i className="fa-solid fa-xmark" style={{ color: 'white', fontSize: '20px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%', height: '20px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></i>
                </button>
              </div>
            ))}
          </div>
          {isRightButtonVisible && (
            <button className="scrollButton right" onClick={() => handleScroll('right')}>›</button>
          )}
        </div>
      )}
      <div className="postBox-footer">
        <div className="postBox-options">
          <span className="option" onClick={handleFileClick}>📷</span>
          <span className="option">GIF</span>
          <span className="option">📊</span>
          <span className="option">😊</span>
          <span className="option">📅</span>
          <span className="option">📍</span>
        </div>
        <button className="postBox-postButton" onClick={handlePostSubmit} disabled={loading || fileLoading}>
          {loading ? 'Posting...' : 'Post'}
        </button>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />
    </div>
  );
};

export default CreatePost;
