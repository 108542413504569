import React, { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Onboarding3() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [answer1, setAnswer1] = useState('');
    const [error, setError] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (userId) {
            fetchUserData(userId);
        }
    }, []);

    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(`${backendURL}/api/user/fetch/${userId}`);
            if (response.ok) {
                const userData = await response.json();
                setAnswer1(userData.answer1 || '');
                setFirstName(userData.firstName);
                setUserEmail(userData.email);
            } else {
                toast.error('Failed to fetch user data.');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('An error occurred while fetching user data.');
        }
    };

    const sendPasswordEmail = async () => {

        try {
            const response = await fetch(`${backendURL}/api/user/send-password-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userEmail, firstName: firstName}),
            });

            if (!response.ok) {
                console.error('Failed to send password email');
            }
        } catch (error) {
            console.error('Error sending password email:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            toast.error('You should register yourself first.');
            return;
        }
         
        if (!answer1.trim()) {
            setError(true);
            return; // Prevent submission if textarea is blank
        }

        try {
            const response = await fetch(`${backendURL}/api/user/update-answer-one/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ answer1 }),
            });

            if (response.ok) {
                await sendPasswordEmail(); 
                sessionStorage.clear();
                navigate('/thank-you', { state: { email: userEmail } }); // Pass email in the state
            } else {
                console.error('Answer one update failed');
            }
        } catch (error) {
            console.error('Answer one update failed:', error);
        }
    };


    return (
        <div className="job-category-container">
        <Helmet>
            <title>Tencil - User's goals</title>
        </Helmet>
            <ToastContainer />
            <h1 className="title">Q1. What are your goals?</h1>
            <p className="subtitle">Tell us your goals, or write a cover letter</p>
            {error && <p className="error-message">Please answer the question</p>}
             <textarea value={answer1} onChange={(e) => setAnswer1(e.target.value)} className="long-textarea" />
           <button onClick={handleSubmit} className="find-jobs-button">Submit</button>
        </div>
    )
}

export default Onboarding3
