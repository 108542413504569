import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ResetPassword() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { token, email } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }

        // setLoading(true);

        try {
            await axios.post(`${backendURL}/api/business/reset-password`, { email, token, newPassword });
            alert('Password has been reset');
            // setLoading(false);
            navigate('/businesslogin');
        } catch (error) {
            alert('Error resetting password');
            // setLoading(false);
        }
    };

    return (
        <div className='reset'>
            {/* {loading && <div className="loader-overlay"><div className="loader"></div></div>} */}
            <Helmet>
                <title>Tencil - Reset password for business</title>
            </Helmet>
            <h2>Reset Password</h2>
            <p>If you've forgotten your password, you can reset it here.</p>
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ height: "45px", width: "300px", borderRadius: "5px" }}
                    required
                /><br/><br/>
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ height: "45px", width: "300px", borderRadius: "5px" }}
                    required
                /><br/><br/>
                <button type="submit">Reset Password</button>
                {/* <button type="submit" disabled={loading}>Reset Password</button> */}
            </form>
        </div>
    );
}

export default ResetPassword;
