import '@fortawesome/fontawesome-free/css/all.css';
import Guest from './components/Guest';
import Auth from './components/Auth';

function App() {
   // If you want to render the authenticated component
   // return <Auth />;

   // If you want to render the guest component
    return <Guest />;
}

export default App;
