import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Userportal() {
    return (
        <div className='app'>
        <Helmet>
            <title>Tencil - User Portal</title>
        </Helmet>
            <img src='logo.jpeg'></img>
            <div>User Portal</div>
            <img src='business.jpg' className='app-img'></img>
            <div>Over 5,000 users waiting for you</div>
            <Link to="/userlogin"><button>Login</button></Link>
            <Link to="/register"><button>Register</button></Link>
        </div>
    )
}

export default Userportal
