import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const BusinessLogin = ({ props }) => {

    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!credentials.email) {
            toast.error('Email is required');
            return;
        }
        if (!credentials.password) {
            toast.error('Password is required');
            return;
        }
        try {
            const response = await fetch(`${backendURL}/api/business/user-login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: credentials.email.trim(), // Ensure trimmed strings
                    password: String(credentials.password).trim() // Ensure trimmed strings
                })
            });
            const json = await response.json();
            if (response.status === 200) {
                localStorage.setItem('token', json.token);
                toast.success("logged in sucessfully");
                setTimeout(() => {
                    navigate('/dashboard');
                }, 1000);
            } else {
                toast.error(json.error);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            toast.error('Error logging in');
        }
    };


    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    return (
        <div>
            <ToastContainer />
            <Helmet>
                <title>Tencil - Business Login</title>
            </Helmet>
            <div className='header'>
                <div className='img-1'>
                    <img src="title.png" alt="Title" className="title-img1" /></div>
                <div className='business'>
                    <div className="hed-1">Welcome Back!</div><br /><br />
                    <div>
                        <form  onSubmit={handleSubmit}>
                            <label htmlFor="email">Email:</label><br />
                            <input type="text" id="email" name="email" value={credentials.email} onChange={handleChange} placeholder='enter your email' />
                            <label htmlFor="password">Password:</label><br />
                            <input type="password" id="password" name="password" value={credentials.password} onChange={handleChange} placeholder='enter your password' /><br /><br />
                            <button type='submit'>Login</button>
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BusinessLogin
