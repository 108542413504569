import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Joi from 'joi';
import { Helmet } from 'react-helmet';

export default function EditProfile({ collapsed, toggleSidebar }) {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [location, setLocation] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [education, setEducation] = useState('');
    const [DOB, setDOB] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [profileImageLink, setProfileImageLink] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            fetchUserData(userId);
        }
    }, []);

    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(`${backendURL}/api/user/fetch/${userId}`);
            if (response.ok) {
                const userData = await response.json();
                setFullName(userData.fullName || '');
                setLocation(userData.location || '');
                setEmail(userData.email || '');
                setEducation(userData.education || '');
                setDOB(userData.DOB ? new Date(userData.DOB).toISOString().split('T')[0] : '');
                setProfileImageLink(userData.profileImage || '');
            } else {
                toast.error('Failed to fetch user data.');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('An error occurred while fetching user data.');
        }
    };

    let userImage = null;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userId = localStorage.getItem('userId');

        if (!userId) {
            toast.error('You should register yourself first.');
            return;
        }

        const schema = Joi.object({
            fullName: Joi.string().trim().required(),
            location: Joi.string().trim().required(),
            education: Joi.string().trim().allow(null, ''),
            DOB: Joi.date().iso().allow(null, ''),
        });
        const { error } = schema.validate({ fullName, location, education, DOB }, { abortEarly: false });

        if (error) {
            const validationErrors = {};
            error.details.forEach((item) => {
                validationErrors[item.path[0]] = item.message;
            });
            setErrors(validationErrors);
        } else {
            try {
                

                // Add profile image if selected and validate its type
                if (profileImage) {
                    if (['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(profileImage.type)) {
                        // formData.append('profileImage', profileImage);
                        userImage = profileImage;
                    } else {
                        toast.error('Only JPEG, JPG, PNG, and GIF images are allowed for profile images!');
                        return;
                    }
                }

                const formData = new FormData();
                formData.append('fullName', fullName);
                formData.append('location', location);
                formData.append('education', education);
                formData.append('DOB', DOB);
                formData.append('profileImage', userImage);

                const response = await fetch(`${backendURL}/api/user/update-profile/${userId}`, {
                    method: 'PUT',
                    body: formData,
                });

                if (response.ok) {
                    navigate('/editprofile');
                } else {
                    const errorMessage = await response.text();
                    try {
                        const errorData = JSON.parse(errorMessage);
                        if (errorData.response) {
                            toast.error(errorData.response);
                        } else {
                            toast.error('An error occurred. Please try again.');
                        }
                    } catch (error) {
                        toast.error('An error occurred. Please try again.');
                    }
                }
            } catch (error) {
                console.error('Profile update failed:', error);
                toast.error('Profile update failed. Please try again later.');
            }
        }
    };

    const handleProfileImageChange = (e) => {
        console.log("asdasd");
        setProfileImage(e.target.files[0]);
        userImage = e.target.files[0];
        handleSubmit(e)
        // No need to trigger handleSubmit here since handleSubmit already handles profile image change
    };

    return (
        <div className={`editprofile${collapsed ? '' : '-collapsed'}`}>
            <Helmet>
                <title>Tencil - Edit your profile</title>
            </Helmet>
            <ToastContainer />
            <div className='edit-profile'>Personal Detail</div>
            <p>Edit Your personal detail</p>
            <p className='img1'>
                <div className="profile-picture-u">
                    <label htmlFor="profile-pic-input">
                        {profileImage && (
                            <img src={URL.createObjectURL(profileImage)} alt="Profile" />
                        )}
                        {!profileImage && profileImageLink && (
                            <img src={`${backendURL}/profileImages/${profileImageLink}`} alt="Profile" />
                        )}
                        <div className="add-icon-u">+</div>
                        <input name="asdASD" type="file" id="profile-pic-input" style={{ display: 'none' }} accept="image/*" onChange={handleProfileImageChange} />
                    </label>
                </div>
            </p>

            <div className='edit-profile1'>
                <div className="container-edit">
                    <form action="">
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="fname">Full Name</label>
                            </div>
                            <div className="col-50">
                                <input type="text" value={fullName} onChange={(e) => { setFullName(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, fullName: null })); }} id="fname" name="fullname" />
                            </div>
                            <div className="col-75">
                                <button onClick={handleSubmit}>Edit</button>
                            </div>
                        </div>
                        {errors.fullName && <div className="error-message">{errors.fullName}</div>}
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="lname">Location</label>
                            </div>
                            <div className="col-50">
                                <input type="text" value={location} onChange={(e) => { setLocation(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, location: null })); }} id="location" name="location" />
                            </div>
                            <div className="col-75">
                                <button onClick={handleSubmit}>Edit</button>
                            </div>
                        </div>
                        {errors.location && <div className="error-message">{errors.location}</div>}
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="col-50">
                                <input type="email" value={email} id="email" name="email" readOnly />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="elavel">Education level</label>
                            </div>
                            <div className="col-50">
                                <input type="text" value={education} onChange={(e) => { setEducation(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, education: null })); }} id="elavel" name="educationlevel" />
                            </div>
                            <div className="col-75">
                                <button onClick={handleSubmit}>Edit</button>
                            </div>
                        </div>
                        {errors.education && <div className="error-message">{errors.education}</div>}
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="dob">Date Of Birth</label>
                            </div>
                            <div className="col-50">
                                <input type="date" value={DOB} onChange={(e) => { setDOB(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, DOB: null })); }} id="dob" name="dob" />
                            </div>
                            <div className="col-75">
                                <button onClick={handleSubmit}>Edit</button>
                            </div>
                        </div>
                        {errors.DOB && <div className="error-message">{errors.DOB}</div>}
                    </form>
                </div>
            </div>
        </div>
    );
}
