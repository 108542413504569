import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-modal';

const Post = ({ post, userData, backendURL }) => {
    const [expandedPosts, setExpandedPosts] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

    const toggleExpanded = (postId) => {
        setExpandedPosts(prevState => ({
            ...prevState,
            [postId]: !prevState[postId]
        }));
    };

    const renderPostContent = (content, postId) => {
        const isExpanded = expandedPosts[postId];
        const truncatedContent = content.length > 200 ? `${content.substring(0, 200)}...` : content;

        return (
            <div className="post-content">
                <div className="read-more-container">
                    <div className={`post-text ${isExpanded ? 'expanded' : ''}`}>
                        {isExpanded ? content : truncatedContent}
                    </div>
                    {content.length > 200 && (
                        <div className="read-more-button" onClick={() => toggleExpanded(postId)}>
                            {isExpanded ? 'Read Less' : 'Read More'}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const openModal = (media, index) => {
        setSelectedMedia(media);
        setCurrentMediaIndex(index);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedMedia([]);
        setModalIsOpen(false);
    };

    const goToPreviousMedia = () => {
        setCurrentMediaIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
    };

    const goToNextMedia = () => {
        setCurrentMediaIndex(prevIndex => (prevIndex < selectedMedia.length - 1 ? prevIndex + 1 : prevIndex));
    };

    const getMediaClass = (count) => {
        switch (count) {
            case 1:
                return 'one-media';
            case 2:
                return 'two-media';
            case 3:
                return 'three-media';
            case 4:
                return 'four-media';
            default:
                return '';
        }
    };

    const media = [...post.postImages, ...post.postVideos];
    const mediaCount = media.length;
    const mediaClass = getMediaClass(Math.min(mediaCount, 4));

    // Ensure userData and profileImage are defined before rendering
    if (!userData || !userData.profileImage) {
        return (
            <div className="post">
                <div className="post-header">
                    <div className="user-info">
                        <div className="user-image-placeholder"></div> {/* Placeholder for image */}
                        <div className="user-text">
                            <div className="userfull-name">Unknown User</div>
                            <div className="post-date">{moment(post.createdAt).fromNow()}</div>
                        </div>
                    </div>
                    <div className="post-content">
                        {renderPostContent(post.postContent, post._id)}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="post">
            <div className="post-header">
                <div className="user-info">
                    <img src={`${backendURL}/profileImages/${userData?.profileImage}`} alt="User" className="user-image" />
                    <div className="user-text">
                        <div className="userfull-name">{userData?.fullName || 'Unknown User'}</div>
                        <div className="post-date">{moment(post.createdAt).fromNow()}</div>
                    </div>
                </div>
                <div className="post-content">
                    {renderPostContent(post.postContent, post._id)}
                </div>
            </div>
            <div className={`video-section ${mediaClass}`}>
                {media.slice(0, 4).map((item, index) => (
                    item.endsWith('.mp4') ? (
                        <div key={index} className="video-player">
                            <video
                                // controls
                                disablePictureInPicture
                                controlsList="nodownload noplaybackrate"
                                onClick={() => openModal(media, index)}
                            >
                                <source src={`${backendURL}/postVideos/${item}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ) : (
                        <img key={index} src={`${backendURL}/postImages/${item}`} alt="Post" className="post-image" onClick={() => openModal(media, index)} />
                    )
                ))}
                {mediaCount > 4 && (
                    <div className="more-media" onClick={() => openModal(media, 0)}>
                        +{mediaCount - 4}
                    </div>
                )}
            </div>
            <hr className="divider" />

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Media Modal"
            >
                <button className="close-modal" onClick={closeModal}><i className="fa-solid fa-xmark"></i></button>
                {currentMediaIndex > 0 && (
                    <button className="scrollButton left" onClick={goToPreviousMedia}>‹</button>
                )}
                <div className="modal-content">
                    {selectedMedia[currentMediaIndex] && (
                        selectedMedia[currentMediaIndex].endsWith('.mp4') ? (
                            <div className="video-player">
                                <video
                                    key={selectedMedia[currentMediaIndex]}
                                    controls
                                    disablePictureInPicture
                                    controlsList="nodownload noplaybackrate"
                                >
                                    <source src={`${backendURL}/postVideos/${selectedMedia[currentMediaIndex]}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        ) : (
                            <img
                                key={selectedMedia[currentMediaIndex]}
                                src={`${backendURL}/postImages/${selectedMedia[currentMediaIndex]}`}
                                alt="Post"
                                className="post-image"
                            />
                        )
                    )}
                </div>
                {currentMediaIndex < selectedMedia.length - 1 && (
                    <button className="scrollButton right" onClick={goToNextMedia}>›</button>
                )}
            </Modal>
        </div>
    );
};

export default Post;
