import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function ForgotPassword() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [email, setEmail] = useState('');
    // const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);

        try {
            await axios.post(`${backendURL}/api/business/forgot-password`, { email });
            alert('Password reset email sent');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                alert('Please enter a registered email id');
            } else {
                alert('Failed to send reset email. Please try again later.');
            }
        } finally {
            // setLoading(false);
        }
    };

    return (
        <div className='forgot'>
            {/* {loading && <div className="loader-overlay"><div className="loader"></div></div>} */}
            <Helmet>
                <title>Tencil - Forgot password for business</title>
            </Helmet>
            <h2>Forgot Password</h2>
            <p>Please enter your email address below. We will send you a link to reset your password.</p>
            <form onSubmit={handleSubmit}>
                <input 
                    type='email' 
                    value={email} 
                    style={{ height: "45px", width: "350px", borderRadius: "5px" }} 
                    placeholder='Enter your email id' 
                    onChange={(e) => setEmail(e.target.value)} 
                /><br/><br/><br/>
                {/* <button type="submit" disabled={loading}>Send Reset Link</button> */}
                <button type="submit">Send Reset Link</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
