import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ThankYouPage = () => {
    const location = useLocation();
    const { email } = location.state || { email: 'your registered' };
    const maskEmail = (email) => {
        const [user, domain] = email.split('@');
        const maskedUser = user.slice(0, 3) + '*****' + user.slice(-2);
        return `${maskedUser}@${domain}`;
    };

    const maskedEmail = maskEmail(email);

    return (
        <div className="thank-you-page">
            <Helmet>
                <title>Tencil - Thank you for registering</title>
            </Helmet>
            <h1 className="thank-title">Thank You!</h1>
            <p className="thank-subtitle">
                An email has been successfully sent to your <span style={{ color: 'black' }}>{maskedEmail}</span> email address. 
                Please check your inbox for further instructions.
            </p>
        </div>
    );
};

export default ThankYouPage;
