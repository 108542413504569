import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Onboarding2() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [jobCategories, setJobCategories] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (userId) {
            fetchUserData(userId);
        }
    }, []);

    const fetchUserData = async (userId) => {
        try {
            const response = await fetch(`${backendURL}/api/user/fetch/${userId}`);
            if (response.ok) {
                const userData = await response.json();
                setJobCategories(userData.jobCategory || '');
            } else {
                toast.error('Failed to fetch user data.');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('An error occurred while fetching user data.');
        }
    };

    const handleCategorySelect = (category) => {
        const isSelected = jobCategories.includes(category);
        if (isSelected) {
            setJobCategories(jobCategories.filter((cat) => cat !== category));
        } else {
            setJobCategories([...jobCategories, category]);
        }
    };

    const handleSubmit = async () => {
        const userId = sessionStorage.getItem('userId');

        if (!userId) {
            toast.error('You should register yourself first.');
            return;
        }

        if (jobCategories.length === 0) {
            setError(true);
        } else {
           
            try {
                const response = await fetch(`${backendURL}/api/user/update-job-category/${userId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ jobCategory: jobCategories }),
                });

                if (response.ok) {
                    navigate('/onboarding3');
                } else {
                    console.error('Job category update failed');
                }
            } catch (error) {
                console.error('Job category update failed:', error);
            }
        }
    };

    return (
        <div className="job-category-container">
        <Helmet>
            <title>Tencil - User job category registration</title>
        </Helmet>
            <ToastContainer />
            <h1 className="title1">Select Your Job Category</h1>
            <p className="subtitle">Select one or more categories suitable for your search</p>
            <div className="category-grid">
                <div className={`category-item ${jobCategories.includes('Marketing') && 'selected'}`} onClick={() => handleCategorySelect('Marketing')}>
                    <i className="fa-solid fa-bullhorn"></i>
                    <p>Marketing</p>
                </div>
                <div className={`category-item ${jobCategories.includes('Public-relations') && 'selected'}`} onClick={() => handleCategorySelect('Public-relations')}>
                    <i className="fa-solid fa-handshake-angle"></i>
                    <p>Public Relations</p>
                </div>
                <div className={`category-item ${jobCategories.includes('HR') && 'selected'}`} onClick={() => handleCategorySelect('HR')}>
                    <i className="fa-solid fa-users"></i>
                    <p>HR</p>
                </div>
                <div className={`category-item ${jobCategories.includes('CX') && 'selected'}`} onClick={() => handleCategorySelect('CX')}>
                    <i className="fa-solid fa-masks-theater"></i>
                    <p>CX</p>
                </div>
                <div className={`category-item ${jobCategories.includes('Sales') && 'selected'}`} onClick={() => handleCategorySelect('Sales')}>
                    <i className="fa-solid fa-chart-line"></i>
                    <p>Sales</p>
                </div>
                <div className={`category-item ${jobCategories.includes('IT') && 'selected'}`} onClick={() => handleCategorySelect('IT')}>
                    <i className="fa-solid fa-laptop"></i>
                    <p>IT</p>
                </div>
                <div className={`category-item ${jobCategories.includes('Design') && 'selected'}`} onClick={() => handleCategorySelect('Design')}>
                    <i className="fa-solid fa-palette"></i>
                    <p>Design</p>
                </div>
                <div className={`category-item ${jobCategories.includes('Frontend-dev') && 'selected'}`} onClick={() => handleCategorySelect('Frontend-dev')}>
                    <i className="fa-solid fa-code"></i>
                    <p>Frontend Dev</p>
                </div>
                <div className={`category-item ${jobCategories.includes('Support') && 'selected'}`} onClick={() => handleCategorySelect('Support')}>
                    <i className="fa-solid fa-headset"></i>
                    <p>Support</p>
                </div>
            </div>
            {error && <p className="error-message">Please select your job category</p>}
            <button className="find-jobs-button" onClick={handleSubmit}>Find Jobs</button>
        </div>
    )
}

export default Onboarding2
