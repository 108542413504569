import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import { Helmet } from 'react-helmet';

export default function StartScreen() {
  const [animationComplete, setAnimationComplete] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimationComplete(true);
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className="container">
        <Helmet>
            <title>Welcome to Tencil</title>
        </Helmet>
            <div className={`title-container${animationComplete ? ' animate' : ''}`}>
                <img src="title.png" alt="Title" className="title-img" />
            </div>
            <div className="options-container">
                {animationComplete && (
                    <>
                        <div className='login-icon start-screen'>
                            <div className='login-i'>
                                <img src='logo.jpeg'></img>
                                <div className='user'>User Portal</div>
                                <Link to="/Register"><button>Register</button></Link>
                            </div>
                            <div className='login-i'>
                                <img src='logo.jpeg'></img>
                                <div className='user'>Business Portal</div>
                                <Link to="/BusinessAccount"><button>Register</button></Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
