import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Sidebar({ collapsed, toggleSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        // setLoading(true);
        localStorage.clear();
        toast.success("Logged out successfully");
        setTimeout(() => {
            // setLoading(false);
            navigate('/');
        }, 1000);
    };

    const handleClick = (e) => {
        e.preventDefault();
        window.open('https://shop.chaching.me', '_blank');
    };

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
            {/* {loading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )} */}
            <ToastContainer />
            <div className="toggle-btn">
                {collapsed && location.pathname === '/companyprofile' && (
                    <>
                        <div className="sidebar-content sidebar-item">
                            <Link to='/search'>
                                <i className="fas fa-arrow-left"></i>
                                <span>Back to Search</span>
                            </Link>
                        </div>
                        <div className="bottom-items">
                            <div className="sidebar-item collapse" onClick={toggleSidebar}>
                                <i className="fa-solid fa-angle-right"></i>
                            </div>
                        </div>
                    </>
                )}
                {!collapsed && location.pathname === '/companyprofile' && (
                    <>
                        <div className="sidebar-content sidebar-item">
                            <Link to='/search'>
                                <i className="fas fa-arrow-left"></i>
                                <span>Back to Search</span>
                            </Link>
                        </div>
                        <div className="bottom-items">
                            <div className="sidebar-item" onClick={toggleSidebar}>
                                <i className="fa-solid fa-angle-left"></i>
                            </div>
                        </div>
                    </>
                )}
                {!collapsed && location.pathname !== '/companyprofile' && (
                    <>
                        <h2>{location.pathname === '/editprofile' ? 'Account' : 'Search'}</h2>
                        <i className='fas fa-arrow-left rotate' onClick={toggleSidebar}></i>
                    </>
                )}
            </div>
            {collapsed && location.pathname !== '/companyprofile' && (
                <>
                    <div className="sidebar-content">
                        <div className={`sidebar-item collapse  ${location.pathname === '/dashboard' ? 'actives' : ''}`}>
                            <Link to='/dashboard'>
                                <i className="fas fa-home"></i>
                            </Link>
                        </div>
                        <div className={`sidebar-item collapse ${location.pathname === '/search' ? 'actives' : ''}`}>
                            <Link to='/search'>
                                <i className="fas fa-search"></i>
                            </Link>
                        </div>
                        <div className={`sidebar-item collapse ${location.pathname === '/createpost' ? 'actives' : ''}`}>
                            <Link to='/createpost'>
                                <i className="fas fa-plus"></i>
                            </Link>
                        </div>
                        <div className={`sidebar-item collapse ${location.pathname === '/cvwriter' ? 'actives' : ''}`}>
                            <Link to='/cvwriter'>
                                <i className="fas fa-user"></i>
                            </Link>
                        </div>
                        <div className={`sidebar-item collapse ${location.pathname === '/shop' ? 'actives' : ''}`}>
                            <Link to='/shop' onClick={(e) => {
                                e.preventDefault();
                                window.open('https://shop.chaching.me', '_blank');
                            }}>
                                <i class="fa-solid fa-bag-shopping"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="bottom-items">
                        <div className="sidebar-item collapse" onClick={toggleSidebar}>
                            <i className="fa-solid fa-angle-right"></i>
                        </div>
                    </div>
                </>
            )}
            {!collapsed && location.pathname !== '/companyprofile' && (
                <>
                    <div className="sidebar-content">
                        <div className={`sidebar-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                            <Link to='/dashboard'>
                                <i className="fas fa-home"></i>
                                <span>Home</span>
                            </Link>
                        </div>
                        <div className={`sidebar-item ${location.pathname === '/search' ? 'active' : ''}`}>
                            <Link to='/search'>
                                <i className="fas fa-search"></i>
                                <span>Search</span>
                            </Link>
                        </div>
                        <div className={`sidebar-item ${location.pathname === '/createpost' ? 'active' : ''}`}>
                            <Link to='/createpost'>
                                <i className="fas fa-plus"></i>
                                <span>Create</span>
                            </Link>
                        </div>
                        <div className={`sidebar-item ${location.pathname === '/cvwriter' ? 'active' : ''}`}>
                            <Link to='/cvwriter'>
                                <i className="fas fa-user"></i>
                                <span>CV Writer</span>
                            </Link>
                        </div>
                        <div className={`sidebar-item ${location.pathname === '/shop' ? 'active' : ''}`}>
                            <Link to='/shop' onClick={(e) => {
                                e.preventDefault();
                                window.open('https://shop.chaching.me', '_blank');
                            }}>
                                <i class="fa-solid fa-bag-shopping"></i>
                                <span>Shop</span>
                            </Link>
                        </div>
                    </div>
                    <div className="bottom-items">
                        <div className={`sidebar-item ${location.pathname === '/editprofile' ? 'active' : ''}`}>
                            <Link to='/editprofile'>
                                <i className="fas fa-user-circle"></i>
                                <span>Account</span>
                            </Link>
                        </div>
                        <div className="sidebar-item" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Sidebar;
