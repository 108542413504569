import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Business() {
    return (
        <div className='app'>
        <Helmet>
            <title>Tencil - Business Portal</title>
        </Helmet>
            <img src='logo.jpeg'></img>
            <div>Business Portal</div>
            <img src='business.jpg' className='app-img'></img>
            <div>Over 5,000 users waiting for you</div>
            <Link to="/businessLogin"><button>Login</button></Link>
            <Link to="/businessAccount"><button>Register</button></Link>
        </div>
    )
}
