import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

function Register() {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation using Joi
        const schema = Joi.object({
            firstName: Joi.string().trim().allow(null).required(),
            lastName: Joi.string().trim().allow(null).required(),
            email: Joi.string().email({ tlds: { allow: false } }).trim().allow(null).required(),
        });
        const { error } = schema.validate(formData, { abortEarly: false });

        if (error) {
            const validationErrors = {};
            error.details.forEach((item) => {
                validationErrors[item.path[0]] = item.message;
            });
            setErrors(validationErrors);
        } else {
            try {
                const response = await fetch(`${backendURL}/api/user/register`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                 if (response.ok) {
                    const userData = await response.json();
                    if(userData.data && userData.data.userId) { // Check if userId exists in response
                        sessionStorage.setItem('userId', userData.data.userId);
                        navigate('/onboarding'); // Navigate to onboarding page
                    } else {
                        console.error('User ID not found in response'); // Log error if userId is not found
                    }
                } else {
                    const errorMessage = await response.text();
                    try {
                        const errorData = JSON.parse(errorMessage);
                        if (errorData.response) {
                            toast.error(errorData.response); // Set error message state
                        } else {
                            toast.error('An error occurred. Please try again.'); // Set generic error message state if response doesn't contain error message
                        }
                    } catch (error) {
                        toast.error('An error occurred. Please try again.'); // Set generic error message state if unable to parse response
                    } 
                }
            } catch (error) {
                console.error('Registration failed:', error);
                toast.error('Registration failed. Please try again later.'); 
            }
        }
    };

    
    return (
        <div className="signup-container">
        <Helmet>
            <title>Tencil - User Registration</title>
        </Helmet>
            <ToastContainer />
            <div className="left-section">
                <img src="register1.avif" alt="Company" className="company-photo" />
            </div>
            <div className="right-section">
                <div className="logo-container">
                    <img src="logo.jpeg" alt="Company Logo" className="company-logo" />
                </div>
                <div className="signup-text">
                    <h2>Welcome to the future of recruitment</h2>
                    <p>Please sign up for an account by providing your name and email</p>
                </div>
                <form onSubmit={handleSubmit} className="signup-form">
                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" className="input-field" />
                    {errors.firstName && <div className="error-message">{errors.firstName}</div>}
                    <br />
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" className="input-field" />
                    {errors.lastName && <div className="error-message">{errors.lastName}</div>}
                    <br />
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Work Email" className="input-field" />
                    {errors.email && <div className="error-message">{errors.email}</div>}
                    <br />
                    <button type="submit" className="create-account-btn">
                        Create Account
                    </button>
                    <div style={{ marginTop: "10px" }}>already an account? <a href='/UserLogin'>Login</a></div>
                </form>
            </div>
        </div>
    );
}

export default Register;
